<template>
  <div class="col col-xl-6">
    <h3>ADMX files for {{ setId }}</h3>

    <div v-if="isLoading">Loading ...</div>

    <p>
      This table shows all the files that are in the win-10-21H1 ADMX set. If a
      language file is missing, or extra files are present, they will be marked.
      Each file is listed alongside its file size and MD5 hash.
    </p>
    <p v-if="model.uniqueLanguageCodes">
      This ADMX set is available in the following
      {{ model.uniqueLanguageCodes.length }} languages:
      <span
        class="badge bg-secondary text-white ml-1"
        v-for="code in model.uniqueLanguageCodes"
        :key="code"
        >{{ code }}</span
      >
    </p>
    <div
      class="alert alert-warning"
      v-if="model.fileGroups.some((x) => x.missingLanguageCodes.length > 0)"
    >
      Some files are missing language files - this means that if the missing
      language was used, Windows will default to the fallback language for the
      ADMX file.
      <div class="form-check mt-2">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          v-model="onlyMissingFiles"
        />
        <label class="form-check-label" for="flexCheckDefault">
          Show only admx files missing language translations
        </label>
      </div>
    </div>

    <div v-if="model.fileGroups" class="d-flex flex-column">
      <div
        v-for="fileGroup in filterFileGroups(
          model.fileGroups,
          onlyMissingFiles
        )"
        :key="fileGroup.key"
        class="mb-3"
      >
        <div class="d-flex justify-content-between">
          <h4>
            {{ fileGroup.key }}
            <small>({{ fileGroup.files.length }} files)</small>
            <small
              v-if="fileGroup.missingLanguageCodes.length > 0"
              class="text-warning ml-2"
              >missing</small
            >
          </h4>
          <div>
            <a
              href="#"
              v-if="!hideFiles.includes(fileGroup.key)"
              v-on:click="hideGroup(fileGroup.key)"
              >hide</a
            >
            <a
              href="#"
              v-if="hideFiles.includes(fileGroup.key)"
              v-on:click="showGroup(fileGroup.key)"
              >show</a
            >
          </div>
        </div>
        <div v-if="!hideFiles.includes(fileGroup.key)">
          <table class="table table-striped table-sm">
            <tr v-for="file in fileGroup.files" :key="file.fullName">
              <td>{{ file.fullName }}</td>
              <td>{{ file.languageCode }}</td>
              <td>{{ file.size | toInt | toLocaleString }} B</td>
              <td class="monospace">{{ file.mD5 | base64toHex | toUpper }}</td>
            </tr>
            <tr v-for="code in fileGroup.missingLanguageCodes" :key="code">
              <td class="text-italic text-warning">Missing</td>
              <td>{{ code }}</td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.monospace {
  font-family: monospace;
}
</style>

<script>
import * as utility from "../utilities.js";

export default {
  created() {
    this.fetchData();
  },
  watch: {
    setId: "fetchData",
  },
  methods: {
    fetchData() {
      const modelParams = {
        setId: this.setId,
      };

      // Reload the tree view, if it has changed
      if (!utility.shallowEqual(this.modelParams, modelParams)) {
        this.modelParams = modelParams;
        this.isLoading = true;

        this.$root.$api.getSetFiles(modelParams.setId).then((data) => {
          if (!utility.shallowEqual(this.modelParams, modelParams)) return;

          this.model = data;
          this.isLoading = false;
        });
      }
    },
    filterFileGroups(fileGroups, onlyMissingFiles) {
      return fileGroups.filter(
        (group) => group.missingLanguageCodes.length > 0 || !onlyMissingFiles
      );
    },
    showGroup(groupId) {
      const index = this.hideFiles.indexOf(groupId);
      if (index > -1) {
        this.hideFiles.splice(index, 1);
      }
    },
    hideGroup(groupId) {
      this.hideFiles.push(groupId);
    },
  },
  computed: {
    setId() {
      return this.$route.params.setId;
    },
  },
  data() {
    return {
      isLoading: false,
      onlyMissingFiles: false,
      model: { fileGroups: [] },
      modelParams: {},
      hideFiles: [],
    };
  },
};
</script>
